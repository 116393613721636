@import "./variables";

@font-face {
  font-family: "BallPill";
  font-weight: 400;
  font-style: normal;
  src: url(../assets/fonts/ballpill-medium-webfont.woff) format('woff'),
       url(../assets/fonts/ballpill-medium-webfont.woff2) format('woff2');
}

html, body {

  visibility: visible;
  padding: 0;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 10px;
  font-family: $ballpill;
  background: $gray;
  color: black;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;

  // @media mq-tablet {
  //   position: relative;
  //   // height: 100%;
  //   overflow-x: hidden;
  // }
}

*, *:before, *:after {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  box-sizing: inherit;
  -webkit-overflow-scrolling: touch;
}

* {
  -webkit-text-size-adjust: none;
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  display: none;
}

*:focus {
  outline-color: #39c;
  outline-width: 5px;
  outline-style: auto;
}

.nav-link {
  font-size: 1.7rem;
  text-transform: uppercase;
  color: black;
  cursor: pointer;

  @include mq-phone-lg {
    font-size: 1.6rem;
  }

  &--desktop {
    @include mq-phone-lg {
      display: none;
    }
  }

  &--mobile {
    display: none;

    @include mq-phone-lg {
      display: block;
    }
  }
}

.controls-copy {
  font-size: 1.8rem;
  text-transform: uppercase;

  @include mq-phone-lg {
    font-size: 1.6rem;
  }
}

p {
  font-size: 1.9rem;

  @include mq-phone-lg {
    font-size: 1.6rem;
  }
}

a {
  color: inherit;
  text-decoration: none;
}
