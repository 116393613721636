@import "styles/variables";
@import "styles/animations";

.shop-wrapper {
  position: absolute;
  width: 100px;
  height: 100px;
  bottom: 24px;
  right: 24px;
  z-index: 4;

  @include mq-phone-lg {
    width: 80px;
    height: 80px;
    bottom: 12px;
    right: 12px;
  }

  // &:hover {
  //   .rotate {
  //     animation: 12s rotate 1 linear;
  //   }
  // }
}

.rotate {
  transform-origin: center center;
  animation: 12s rotate infinite linear;
}
