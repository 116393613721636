@import "styles/variables";

.top-nav {
  position: absolute;
  width: 324px;
  height: 85px;
  margin: 22px 22px 22px 6px;
  z-index: 4;

  @include mq-phone-lg {
    width: 145px;
    height: 42px;
    margin: 12px 12px 12px 4px;
  }
}
