@import "styles/variables";

.modal-wrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, #00FA9A 0%, rgba(196, 196, 196, 0.9) 50%);
  z-index: 4;

  @include mq-phone-lg {
    align-items: flex-start;
    padding: 24px 0;
    overflow: scroll;
  }
}

.modal-transition-appear {
  opacity: 0;
  transition: all 200ms ease-in-out;
}

.modal-transition-appear-active {
  opacity: 1;
}

.modal-content {
  width: 75%;
  height: 85vh;
  overflow: scroll;
  animation: moveUp 350ms ease-in-out;
  box-shadow: 0 0 8px 8px white, inset 0px 0px 6px 6px white;

  @include mq-phone-lg {
    width: 100%;
    padding: 0 24px;
  }

  &__top {
    display: flex;
    position: relative;
    padding-bottom: 30px;
    margin-top: 30px;

    h1 {
        font-weight: normal;
      }

    &:first-of-type {
      margin-top: 0;
    }

    &--header {
      display: flex;
      align-items: center;
      flex-basis: calc(100% - 100px);
      height: 90px;
      margin-right: 8px;
      background-color: white;
      border: 1px solid black;
      border-radius: 15px;
      padding: 32px;
      font-size: 1.6rem;
      font-weight: normal;
      box-shadow: 0 0 8px 8px white, inset 0px 0px 6px 6px white;

      @include mq-phone-lg {
        height: 80px;
        padding: 22px;
        flex-basis: calc(100% - 90px);

        h1 {
          font-size: 2.4rem;
        }
      }
    }

    &--close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      width: 90px;
      height: 90px;
      right: calc(25%/2);
      background-color: $green;
      cursor: pointer;
      border: 1px solid black;
      border-radius: 15px;
      padding: 24px 32px 32px;
      font-size: 4.2rem;
      box-shadow: 0 0 8px 8px white, inset 0px 0px 6px 6px white;
      transition: all 200ms ease-in-out;

      &:hover {
        background-color: white;
      }

      @include mq-phone-lg {
        width: 80px;
        height: 80px;
        right: calc(10%/2);
      }
    }
  }

  &__bottom {
    background-color: white;
    border: 1px solid black;
    border-radius: 15px;
    padding: 32px;
    box-shadow: 0 0 8px 8px white, inset 0px 0px 6px 6px white;

    p, h1 {
      margin-bottom: 24px;
      font-size: 2.6rem;
      font-weight: normal;
      line-height: 1.5;
    }

    p {
      font-size: 2rem;
    }

    p:last-of-type {
      margin: 0;
    }

    .underline {
      border-bottom: 3px solid;
      border-color: rgba(0, 250, 154, 1);
      transition: 0.3s ease all .1s;
    }

    .underline:hover {
      border-color: rgba(255, 255, 255, 1);
    }

    @include mq-phone-lg {
      padding: 22px;

      h1 {
        font-size: 2.4rem;
      }

      p {
        font-size: 1.8rem;
      }

    }

  }
}


@keyframes moveUp {
  from {
    transform: translateY(10px);
  }
  to {
    transform: translateY(0);
  }
}
