$ballpill: 'BallPill', sans-serif;

// colors
$gray: #C4C4C4;
$green: #00FA9A;
$red: #F7ABC6;
$blue: #7995F7;
$lavendar: #D3C2F4;

// svg colors
$svg-blue: #62D4FB;
$svg-purple: #7995F7;
$svg-orange: #E0A890;
$svg-lime: #B8FF4F;

// breakpoints
$bp-sm: 580px;
$bp-md: 800px;
$bp-lg: 1000px;
$bp-xl: 1400px;

$mobile-height: calc(100vh - 106px);

//break rules
@mixin mq-phone {
   @media (max-width: #{$bp-sm}) {
       @content;
   }
}

@mixin mq-phone-lg {
   @media (max-width: #{$bp-md}) {
       @content;
   }
}

@mixin mq-tablet {
   @media (max-width: #{$bp-lg}) {
       @content;
   }
}

@mixin mq-desktop {
   @media (max-width: #{$bp-xl}) {
       @content;
   }
}
