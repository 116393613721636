@import "styles/variables";

figure {
  position: relative;
  width: 100%;
  height: 100%;

  &.absolute {
    position: absolute;
    top: 0;
  }

  &.viewer-image {
    position: absolute;
    width: auto;
    min-width: 100vw;
    height: auto;
    min-height: 100vh;
    left: 0;
    top: 0;

    @include mq-phone-lg {
      top: 50%;
    }
  }
}

.image {
  width: 100%;
  min-height: 100vh;

  @include mq-phone-lg {
    width: auto;
    height: 100vh;
    transform: translate3d(0, -50%, 0);
  }
}
