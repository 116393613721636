@import "styles/variables";
@import "styles/animations";

.viewer-transition-appear {
  opacity: 0;
  transform: scale(0.6);
}

.viewer-transition-appear-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 1000ms ease-in-out 200ms, transform 600ms 400ms;
}

.viewer-transition-exit .viewer.active {
  // transform: scale(1);
  opacity: 1;
  clip-path: ellipse(250px 125px at 50% 50%);
}

.viewer-transition-exit-active .viewer.active {
  // transform: scale(5);
  opacity: 0;
  clip-path: ellipse(100% 100% at 50% 50%);
  transition: clip-path 2500ms ease-in-out, opacity 1000ms ease-in-out 4500ms;
}

.viewer-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100vh;

  @include mq-phone-lg {
    width: auto;
    height: $mobile-height;
  }
}

.viewer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 500px;
  height: 250px;
  border: 1px solid black;
  border-radius: 50%;
  background: $green;
  overflow: hidden;
  transform: scale(0);
  animation: landingViewer 1200ms ease-in-out forwards 600ms;
  z-index: 2;

  @include mq-phone-lg {
    width: 85%;
    height: 140px;
  }

  &:before {
    content: 'Select a biome to commence your renaturing';
    position: absolute;
    left: 50%;
    top: 50%;
    text-align: center;
    transform: translate3d(-50%, -50%, 0);
    font-size: 2.2rem;
    line-height: 1.5;

    @include mq-phone-lg {
      font-size: 1.6rem;
    }
  }

  &.active:before {
    content: '';
  }

  &.active {
    clip-path: ellipse(250px 125px at 50% 50%);
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 0;

    @include mq-phone-lg {
      clip-path: ellipse(159px 70px at 50% 50%);
    }
  }

  &__canvas-wrapper {
    z-index: 1;

    @include mq-phone-lg {
      width: 250vw;
      height: 100vh;
    }
  }

  canvas {
    width: auto;
    min-height: 100vh;
    pointer-events: none;
    background-color: transparent;

    @include mq-phone-lg {
      width: 250vw!important;
    }
  }
}

.controls-transition-appear {
  opacity: 0;
}

.controls-transition-appear-active {
  opacity: 1;
  transition: all 500ms ease-in-out 1000ms;
}

.topography-transition-exit {
  opacity: 1;
  // transform: translate3d(0, 0, 0);
}

.topography-transition-exit-active {
  opacity: 0;
  // transform: translate3d(100%, 0, 0);
  transition: all 1000ms ease-in-out;
}

.env-link {
  position: absolute;
  padding: 16px 22px;
  font-size: 2.2rem;
  border: 1px solid black;
  border-radius: 20px;
  transition: all 120ms ease-in-out;
  cursor: pointer;
  z-index: 0;

  @include mq-phone-lg {
    font-size: 1.8rem;
    background-color: $gray;
    padding: 16px 16px;
  }

  &--north {
    top: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);

    &:hover,
    &:focus {
      background-color: $red;
    }
  }

  &--south {
    bottom: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);

    &:hover,
    &:focus {
      background-color: $green;
    }
  }

  &--west {
    top: 50%;
    left: 0;
    transform: translate3d(0, -50%, 0);

    @include mq-phone-lg {
      top: 75%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }

    &:hover,
    &:focus {
      background-color: $blue;
    }
  }

  &--east {
    top: 50%;
    right: 0;
    transform: translate3d(0, -50%, 0);

    @include mq-phone-lg {
      top: 25%;
      right: 50%;
      transform: translate3d(50%, -50%, 0);
    }

    &:hover,
    &:focus {
      background-color: $lavendar;
    }
  }

  &.active,
  &:hover {
    box-shadow: 0 0 8px 8px white, inset 0px 0px 6px 6px white;
  }

  &.hide {
    display: none;
  }
}

.environment .env-link {
  @include mq-phone-lg {
    bottom: 0%;
    left: 50%;
    right: auto;
    top: auto;
    transform: translate3d(-50%, 0, 0);
  }
}

.back-button {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 22px;
  cursor: pointer;
  z-index: 4;

  @include mq-phone-lg {
    margin: 12px;
  }
}
