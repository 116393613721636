@import "styles/variables";

.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  top: 0;
  background: $gray;
  // transition: all 300ms ease-in-out 4000ms;

  @include mq-phone-lg {
    height: $mobile-height;
  }

  &.flash {
    // animation: fadeInOut 800ms ease-in-out;

    .viewer-wrapper {
      animation: openClose 1000ms ease-in-out;
    }
  }

  &.clip {
    clip-path: ellipse(80% 100%);
  }

  &.north {
    background: linear-gradient(180deg, #F7ABC6 37.5%, #00FA9A 100%);
  }

  &.south {
    background: linear-gradient(180deg, #D3C2F4 43.23%, #52F49D 100%);
  }

  &.east {
    background: linear-gradient(90deg, #B8FF4F 0%, #D3C2F4 73.96%);
  }

  &.west {
    background: linear-gradient(90deg, #7995F7 19.27%, #F7ABC6 100%);
  }
}

.link-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  margin: 22px;
  z-index: 4;

  @include mq-phone-lg {
    margin: 12px;
  }
}

.plus-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate3d(-50%, -50%, 0);

  @include mq-phone-lg {
    width: 105vw;
  }

  &--no-animation .animate-line {
    animation: none;
    stroke-dashoffset: 0;
  }

  &--shorten {
    .plus-svg {
      width: calc(100% - 244px);
      height: calc(100% - 135px);

      @include mq-phone-lg {
        width: 105vw;
        height: 100%;
      }
    }
  }

  .plus-svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

.animate-line {
  stroke-dashoffset: 1000;
  stroke-dasharray: 1000;
  animation: plusGrow 1500ms ease-in-out forwards 2000ms;
}

.animate-topography-enter {
  transform: translate3d(99.5%, 0, 0);
  animation: topographyEnter 2200ms ease-in-out forwards 1500ms;
}

.animate-topography {
  animation: topographyEase 5000ms ease-in-out infinite;
}

.topography {
  path {
    transition: stroke 1000ms ease-in-out;
  }

  &.landing {
    left: 0;
  }

  &.north {
    path {
      stroke: $svg-purple;
    }
  }
  &.south {
    path {
      stroke: $svg-blue;
    }
  }
  &.east {
    path {
      stroke: $svg-orange;
    }
  }
  &.west {
    path {
      stroke: $svg-lime;
    }
  }
}
