@import "styles/variables";

.environment-transition-enter {
  opacity: 0;
}

.environment-transition-enter-active {
  opacity: 1;
  transition: opacity 600ms ease-in-out;
}

.environment-transition-exit,
.environment-transition-exit .text-container {
  opacity: 1;
}

.environment-transition-exit-active,
.environment-transition-exit-active .text-container {
  opacity: 0;
  transition: opacity 550ms ease-in-out;
}

.environment-transition-exit-done .text-container {
  opacity: 0;
}

.environment-container {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 3;

  @include mq-phone-lg {
    height: $mobile-height;
  }

  &.north {
    background: linear-gradient(180deg, #F7ABC6 37.5%, #00FA9A 100%);
  }

  &.south {
    background: linear-gradient(180deg, #D3C2F4 43.23%, #52F49D 100%);
  }

  &.east {
    background: linear-gradient(90deg, #B8FF4F 0%, #D3C2F4 73.96%);
  }

  &.west {
    background: linear-gradient(90deg, #7995F7 19.27%, #F7ABC6 100%);
  }

  .no-hover {
    pointer-events: none;

    .env-link--north {
      background-color: $red;
    }

    .env-link--south {
      background-color: $green  ;
    }

    .env-link--east {
      background-color: $lavendar;
    }

    .env-link--west {
      background-color: $blue;
    }
  }
}

.environment-wrapper {
  position: absolute;
  width: 65%;
  max-width: 1200px;
  height: 80vh;
  max-height: 500px;
  top: 46%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);

  @include mq-phone-lg {
    width: 85%;
    height: 50vh;
  }
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 36px;
  overflow: hidden;

  &.animate {
    animation-name:
      fade, upTop;
    animation-duration:
      400ms, 800ms;
    animation-timing-function:
      ease-in-out, ease-in-out;
    animation-iteration-count:
      1, 1;
  }

  @include mq-phone-lg {
    width: 95%;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
}

.text-container {
  position: absolute;
  top: 50%;
  transform: translate3d(-10%, -50%, 0);
  text-transform: lowercase;

  @include mq-phone-lg {
    width: 100%;
    padding: 0 24px;
  }

  &.animate {
    opacity: 0;
    animation: fade 600ms ease-in-out 300ms forwards;
  }

  &__line {
    position: relative;
    margin-bottom: 32px;
    opacity: 0;
    font-size: 4.2rem;
    line-height: 1.5;
    color: white;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;

    @include mq-phone-lg {
      font-size: 2rem;
      -webkit-text-stroke-width: 0px;
      color: black;
    }

    &:nth-child(1) {
      transform: translate3d(random(50) + px, 0, 0);
      animation: fadeUp 300ms ease-in-out 1000ms forwards;

      @include mq-phone-lg {
        transform: translate3d(random(50) + px, 0, 0);
      }
    }

    &:nth-child(2) {
      transform: translate3d(random(300) + px, 0, 0);
      animation: fadeUp 400ms ease-in-out 1900ms forwards;

      @include mq-phone-lg {
        transform: translate3d(random(100) + px, 0, 0);
      }
    }

    &:nth-child(3) {
      transform: translate3d(random(100) + px, 0, 0);
      animation: fadeUp 400ms ease-in-out 2700ms forwards;

      @include mq-phone-lg {
        transform: translate3d(random(75) + px, 0, 0);
      }
    }
  }
}

.selectors-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}

.selector {
  width: 45px;
  height: 18px;
  margin: 0 12px;
  border: 1px solid black;
  border-radius: 50%;
  background-color: black;
  cursor: pointer;

  &.active {
    background-color: white;
    box-shadow: 0 0 8px 8px white;
  }
}

.selector:hover {
  box-shadow: 0 0 8px 8px white;
}
