@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeUp {
  from {
    opacity: 0;
    top: 10px;
  }
  to {
    opacity: 1;
    top: 0;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@keyframes openClose {
  0% {
    clip-path: ellipse(250px 125px at 50% 50%);
  }
  30% {
    clip-path: ellipse(200px 15px at 50% 50%);
  }
  100% {
    clip-path: ellipse(250px 125px at 50% 50%);
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes upTop {
  from {
    top: 10px;
  }
  to {
    top: 0;
  }
}

@keyframes landingViewer {
  0% {
    transform: scale(0.05);
  }
  90% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes plusGrow {
  0% {
    stroke-dashoffset: 1000;
  }
  85% {
    stroke-dashoffset: 350;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes topographyEnter {
  from {
    transform: translate3d(99%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes topographyEase {
  0% {
    transform: translate3d(0, 0, 0);
  }
  15% {
    transform: translate3d(5px, 2px, 0);
  }
  40% {
    transform: translate3d(3px, -2px, 0);
  }
  65% {
    transform: translate3d(-2px, 4px, 0);
  }
  85% {
    transform: translate3d(1px, 2px, 0);
  }
  100% {
    transform: translate3d(0px, 0, 0);
  }
}
